import React, {useState, useEffect} from 'react'
import axios from 'axios';
import styled from 'styled-components'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
//import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import LoadingContainer from './reusableComponent/loadingContainer';
import { getResponseMessage } from './responding';
import colors from '../colors';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Body = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: row;
`;

const BodyLogin = styled.div`
  width: 50%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 900px) {
      width: 100% ;
      
    }
`;
const BodyLoginContent = styled.div`
  width: 60%;
  min-height: 300px;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-top: 20%;
  max-width: 400px;
  padding: 10px;
  @media only screen and (max-width: 900px) {
      width: 80% ;
      margin-top: 20%;
      background-color: white;
    }
`;

const BodyImage = styled.div`
  width: 42%;
  padding: 15px 4%;
  height: calc(100vh - 30px);
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center ;
  //background-image: url("/assets/img/login6.png") ;
  background-position: center;
  background-size: cover;
  background-color: ${colors.primary};
  @media only screen and (max-width: 900px) {
      display:none ;
    }
`;




const Title = styled.h1`
  font-size: x-large;
  padding: 0px 0px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 0%;
`;

const Paragr = styled.p`
  margin: 0%;
  padding: 10px 0px;
  margin-bottom: 30px;
`;

const MyLink = styled(Link)`
    //text-decoration-line: none;
    color: black;
`

const Logo = styled.img`
  width: 120px;
  height: auto;
  align-self: center;
`;
// import logo from './assets/img/logo192.png'

export default function Login({setOpenTermsModal, Language}) {

  const [btnContent, setBtnContent] = useState('login')
  const [isEmailError, setIsEmailError] = useState(false)
  const [isPasswordError, setIsPasswordError] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [openAlert, setOpenAlert] = useState(false)
  const navigate = useNavigate()
  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [disabledBtn, setdisabledBtn] = useState(false);

  function login(email, password){
    setBtnContent(<CircularProgress style={{color: 'white', width: '30px', height:'30px'}}></CircularProgress>)
    setIsEmailError(false)
    setIsPasswordError(false)
    setEmailError('')
    setPasswordError('')
    setdisabledBtn(true)

    axios.post("/login", {
      email, password
    })
        .then(function (response) {
          setdisabledBtn(false)
          // handle success
          //setSousCategory(response.data.souscategories)
          setBtnContent('login')
          console.log(response.data)
          if(response.data.result === "connected"){
            setOpen(true)
            setAlertMessage(response.data.message)
            setAlertType("success")
            setTimeout(() => {
              navigate('/app', { replace: true });
            }, 1000);
          }
          else if(response.data.result === "input_error") {
            for(let i = 0; i < response.data.error.length; i++){
              if(response.data.error[i].path === 'email'){
                setIsEmailError(true)
                setEmailError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              }
              else{
                setIsPasswordError(true)
                setPasswordError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
              }
            }
          }
          else if(response.data.result === "server_error") {
            setOpen(true)
            setAlertMessage(response.data.message)
            setAlertType("error")
          }
          else{
            setOpen(true)
            setAlertMessage('Session ended')
            setAlertType("error")
            setTimeout(() => {
              navigate('/login', { replace: true });
            }, 2000);
          }
          
        })
        .catch(function (error) {
          // handle error
          setBtnContent('login')
          setdisabledBtn(false)
          console.log("myerror", error);
          if(error.response.status){
            setOpen(true)
            setAlertMessage(getResponseMessage(error.response.status))
            setAlertType("error")
          }else{
            setOpen(true)
            setAlertMessage('Internal server error')
            setAlertType("error")
          }
        })
    
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const [isLoading, setisLoading] = useState(true);
  
  useEffect(() => {
    // get data from database
    axios.post("/check_login")
    .then(function (response) {
      console.log(response.data)
      if(response.data.redirect === false){
        navigate('/App', { replace: true });
      }
      else{
          setisLoading(false)
      }
    })
    .catch(function (error) {
      setisLoading(false)
      // handle error
      console.log(error);
      if(error.response){
        setOpenAlert(true)
        setAlertMessage(getResponseMessage(error.response.status))
        setAlertType("error")
      }else{
        setOpenAlert(true)
        setAlertMessage('Internal server error')
        setAlertType("error")
      }
    })
  
  }, [])




 if(isLoading){
    return(
      <Body>
        <LoadingContainer open={isLoading}></LoadingContainer>
      </Body>
    )  
 }else{
    return (
      <Body>
          <BodyLogin>
              <BodyLoginContent id='BodyLoginContent' >
                  <Logo src={"./assets/img/logo192.png"} />
                  
                  <Title>{Language['Login to your account']}</Title>
                  <Paragr textAlign={"right"} style={{width: "100%", textAlign: 'left', color: 'grey'}} >{Language['The faster you fill up, the faster your business grow.']}</Paragr>

              
                  <FormControl error={isEmailError} variant="standard">
                      <InputLabel htmlFor="email">{Language['Email']}</InputLabel>
                      <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { login(email, password)}}}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setIsEmailError(false)
                        setEmailError('')
                      }}
                      id="email"
                      defaultValue=""
                      aria-describedby="email_error"
                      />
                      <FormHelperText id="email_error">{emailError}</FormHelperText>
                  </FormControl>

                  <FormControl error={isPasswordError} variant="standard">
                      <InputLabel htmlFor="password">{Language['Password']}</InputLabel>
                      <Input
                      onKeyDown={ (event) => { if (event.key === 'Enter') { login(email, password)}}}
                      type='password' 
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError('')
                        setIsPasswordError(false)
                      }}
                      id="password"
                      defaultValue=""
                      aria-describedby="password_error"
                      />
                      <FormHelperText id="password_error">{passwordError}</FormHelperText>
                  </FormControl>

                  <Button variant="contained" disableElevation disabled={disabledBtn}  style={{marginTop: '10px', backgroundColor: colors.primary}} onClick={() => login(email, password)}>
                  {Language[btnContent] ? Language[btnContent] : btnContent }
                  </Button>
                  <p style={{width: "100%", textAlign: 'right', color: 'black', fontSize:'small'}} >{Language['Forgot password?']} <MyLink style={{color: colors.primary}} to="/forgot_password" >{Language['Reset password.']}</MyLink></p>

                  
                  <Paragr style={{width: "100%", display: 'flex', flexDirection: 'column',justifyContent: "end" , textAlign: 'center',alignSelf: 'end', color: 'grey', flexGrow: "1"}} ><span>{Language["You don't have an account?"]} <MyLink to="https://linksy.tech/create/" > {Language['create account']}</MyLink></span></Paragr>

                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                      {Language[alertMessage] ? Language[alertMessage] : alertMessage}
                    </Alert>
                  </Snackbar>
              </BodyLoginContent>
          </BodyLogin>
          <BodyImage>
            <Logo style={{alignSelf: 'center', width: '70px'}} src={"./assets/img/logo192white.png"} />
            <div style={{justifyContent: 'center'}}>
              <Title style={{color: 'white', fontSize: '40px', textAlign: 'left', width: '100%', marginTop: '0px'}}>{Language['Let us help you grow your business']}</Title>
              <Paragr style={{color: '#ffffffcc', fontSize: '17px', width: "100%"}}>{Language['We offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.']}</Paragr>
            </div>
            <>
            <Paragr style={{color: 'white', fontSize: '15px', width: "100%", textAlign:'center', margin: 0}}><MyLink to="https://linksy.tech/terms-and-conditions/" style={{color: "white"}} >{Language['Our Conditions & Terms.']}</MyLink></Paragr>
            </>
          </BodyImage>
      </Body>
    )
 }

  
}
 
