const english = {
    'Welcome':'Welcome',
    'to Linksy Platform':'to Linksy Platform',
    'The best Marketing tool in Algeria!':'The best Marketing tool in Algeria!',
    'Expired in':'Expired in',
    'Email Marketing':'Email Marketing',
    'Contacts':'Contacts',
    'SMS Marketing':'SMS Marketing',
    'Logout':'Logout',
    'My account':'My account',
    'Configuration':'Configuration',
    'Reporting':'Reporting',
    'Contacts list':'Contacts list',
    'set my SMS Account!':'set my SMS Account!',
    'Unable to send message before setting your SMS account':'Unable to send message before setting your SMS account',
    'Sender':'Sender',
    'Create Group':'Create Group',
    'no option':'no option',
    'Create Contact':'Create Contact',
    'Show contacts by groups':'Show contacts by groups',
    'Message':'Message',
    'Please note that messages containing Arabic characters will be counted as one message if they are 70 characters or less. Messages without any Arabic characters will be counted as one message if they are 160 characters or less.':'Please note that messages containing Arabic characters will be counted as one message if they are 70 characters or less. Messages without any Arabic characters will be counted as one message if they are 160 characters or less.',
    'Characters':'Characters',
    'programme':'programme',
    'send':'send',
    'Pragramme a message':'Pragramme a message',
    'Fill the field below then press programme, this action will send your message at the selected time.':'Fill the field below then press programme, this action will send your message at the selected time.',
    'Select date':'Select date',
    'Time':'Time',
    'Repeted':'Repeted',
    'None':'None',
    'monthly':'monthly',
    'yearly':'yearly',
    'Cancel':'Cancel',
    'Create New Contact':'Create New Contact',
    'This is a quick way to create a contact and assemble it in a new group.':'This is a quick way to create a contact and assemble it in a new group.',
    'Group Name':'Group Name',
    'Contact':'Contact',
    'fill the field below then press create, this action will create a new contact according to this information.':'fill the field below then press create, this action will create a new contact according to this information.',
    'create':'create',
    'society':'society',
    'First Name':'First Name',
    'Family Name':'Family Name',
    'Phone Number':'Phone Number',
    'email':'email',
    'add new contact':'add new contact',
    'Add':'Add',
    'import':'import',
    'All groups':'All groups',
    'delete contacts':'delete contacts',
    'export contacts':'export contacts',
    'group contacts':'group contacts',
    'Edit Groups':'Edit Groups',
    'You can easily delete a group definitly by clicking on the trash icon.':'You can easily delete a group definitly by clicking on the trash icon.',
    'contacts':'contacts',
    'Done':'Done',
    'edit groups':'edit groups',
    'Select a group':'Select a group',
    'Select one of the previous group to add the selected contacts in one group.':'Select one of the previous group to add the selected contacts in one group.',
    'Group':'Group',
    'none':'none',
    'or':'or',
    'Create a new group':'Create a new group',
    'Title':'Title',
    'Save':'Save',
    'save':'save',
    'Import New Contacts':'Import New Contacts',
    'file':'file',
    'text':'text',
    'import new contacts by uploading a csv file':'import new contacts by uploading a csv file',
    'import new contacts by copying & pasting lines':'import new contacts by copying & pasting lines',
    'Click or drag file to this area to upload':'Click or drag file to this area to upload',
    'Support for a single or bulk upload. Strictly prohibit from uploading company data or other':'                            band files',
    'Creating and adding in a group':'Creating and adding in a group',
    'Add imported contacts to a group':'Add imported contacts to a group',
    'group':'group',
    'Import':'Import',
    'to import your contacts easily.':'to import your contacts easily.',
    'CSV template':'CSV template',
    'configrations of the imported file.':'configrations of the imported file.',
    'last step before uploading your contacts, choose the right columns then click import':'last step before uploading your contacts, choose the right columns then click import',
    'update existing items according the phone number':'update existing items according the phone number',
    'Society':'Society',
    'Email':'Email',
    'Delete Contact':'Delete Contact',
    'delete':'delete',
    'ok':'ok',
    'Update Contact':'Update Contact',
    'fill the field below then press update, this action will update this contact according to this information.':'fill the field below then press update, this action will update this contact according to this information.',
    'update':'update',
    'message':'message',
    'scheduled':'scheduled',
    'balance':'balance',
    'total message sent':'total message sent',
    'Search':'Search',
    'messages found':'messages found',
    'Credit available':'Credit available',
    'total costs':'total costs',
    'e.g. "names", "senders", "phone numbers"..':'e.g. "names", "senders", "phone numbers"..',
    'Filter':'Filter',
    'Cancel Filter':'Cancel Filter',
    'Export':'Export',
    'SMS':'SMS',
    'Life Time':'Life Time',
    'advanced':'advanced',
    'this year':'this year',
    'this month':'this month',
    'This Month':'This Month',
    'This Year':'This Year',
    'Advanced Filtering':'Advanced Filtering',
    'Filter Messages':'Filter Messages',
    'use the fields below to filter messages than press filter button.':'use the fields below to filter messages than press filter button.',
    'All':'All',
    'Operator':'Operator',
    'Status':'Status',
    'Failed':'Failed',
    'Sent':'Sent',
    'From':'From',
    'To':'To',
    'Filter scheduled messages':'Filter scheduled messages',
    'use the fields below to filter messages.':'use the fields below to filter messages.',
    'Send At':'Send At',
    'year':'year',
    'month':'month',
    'no repeat':'no repeat',
    'use the fields below to filter messages based on the date range you specify.':'use the fields below to filter messages based on the date range you specify.',
    'Configurations':'Configurations',
    'SMS configuration':'SMS configuration',
    'Source':'Source',
    'Username':'Username',
    'Password':'Password',
    'SMS senders':'SMS senders',
    'Confirm New Password':'Confirm New Password',
    'New Password':'New Password',
    'Actuel Password':'Actuel Password',
    'Security':'Security',
    'Personal Information':'Personal Information',
    'Disconnect':'Disconnect',
    'Mailing lists':'Mailing lists',
    'Mailing contacts':'Mailing contacts',
    'Apps':'Apps',
    'Profile':'Profile',
    'Mailing Lists':'Mailing Lists',
    'Mailing Contacts':'Mailing Contacts',
    'set my Account!':'set my Account!',
    'unable to send message before setting your SMS sending account':'unable to send message before setting your SMS sending account',
    'Create Mailing List':'Create Mailing List',
    'Reply to (option)':'Reply to (option)',
    'Subject':'Subject',
    'Editor type':'Editor type',
    'Attach files':'Attach files',
    'Program':'Program',
    'Send':'Send',
    'Code':'Code',
    'fill the field below then press programme, this action will send your message at the selected time.':'fill the field below then press programme, this action will send your message at the selected time.',
    'select date':'select date',
    'Create New Mailing List':'Create New Mailing List',
    'This is a quick way to mailing contact and assemble it in a new mailing list.':'This is a quick way to mailing contact and assemble it in a new mailing list.',
    'Mailing list':'Mailing list',
    'Mailing contact':'Mailing contact',
    'New':'New',
    'fill the field below then press create, this action will create a new Mailing List.':'fill the field below then press create, this action will create a new Mailing List.',
    'title':'title',
    'Edit list title':'Edit list title',
    'Delete list':'Delete list',
    'import new contacts':'import new contacts',
    'Delete contacts':'Delete contacts',
    'Remove from list':'Remove from list',
    'Export contacts':'Export contacts',
    'Edit Title':'Edit Title',
    'Once you are satisfied with your new title, press the "update" button to update your list title.':'Once you are satisfied with your new title, press the "update" button to update your list title.',
    'Update':'Update',
    'Add your contacts.':'Add your contacts.',
    'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files':'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
    'Download our':'Download our',
    'Configrations of the imported file':'Configrations of the imported file',
    'Phone (obligated)':'Phone (obligated)',
    'Delete confirmation':'Delete confirmation',
    'Are you sure to delete this':'Are you sure to delete this',
    'Delete':'Delete',
    'Remove Contacts':'Remove Contacts',
    'Are you sure to remove the contacts from this list ?':'Are you sure to remove the contacts from this list ?',
    'Add to list':'Add to list',
    'Mailing List':'Mailing List',
    'Add your contacts to a mailing list.':'Add your contacts to a mailing list.',
    'Select a list':'Select a list',
    'Remove':'Remove',
    'Are you sure to delete this ':'Are you sure to delete this ',
    'Add List':'Add List',
    'You can easily add or remove this mailing contact from your mailing lists.':'You can easily add or remove this mailing contact from your mailing lists.',
    'SMTP configuration':'SMTP configuration',
    'Email Address':'Email Address',
    'SMTP server host':'SMTP server host',
    'Port':'Port',
    'SMTP server port':'SMTP server port',
    'Account username':'Account username',
    'Account password':'Account password',
    'SMTP Accounts':'SMTP Accounts',
    'messages':'messages',
    'hour':'hour',
    'SMTP Verification':'SMTP Verification',
    'We have send a code to your email address, enter the code to validate your smtp account.':'We have send a code to your email address, enter the code to validate your smtp account.',
    'check':'check',
    'SMTP Settings':'SMTP Settings',
    'Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host.':'Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host.',
    'Limit per hour':'Limit per hour',
    'Close':'Close',
    'are you sure you want to delete this email?':'are you sure you want to delete this email?',
    'no item is selected, choose in minimum one item.':'no item is selected, choose in minimum one item.',
    'Delete Email':'Delete Email',
    'refresh':'refresh',
    'The requested url not found, it seems like it changed or deleted.':'The requested url not found, it seems like it changed or deleted.',
    'URL Not Found!':'URL Not Found!',
    'Login to your account':'Login to your account',
    'The faster you fill up, the faster your business grow.':'The faster you fill up, the faster your business grow.',
    'login':'login',
    'Reset password.':'Reset password.',
    'Contact admin':'Contact admin',
    "You don't have an account?":"You don't have an account?",
    'Let us help you grow your business':'Let us help you grow your business',
    'We offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.':'We offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.',
    'Our Conditions & Terms.':'Our Conditions & Terms.',
    'Reset your password':'Reset your password',
    'You can reset your password using email to find your account.':'You can reset your password using email to find your account.',
    'Reset Password':'Reset Password',
    'We have already send you a password reset link to your email address, check your mail inbox to reset your password.':'We have already send you a password reset link to your email address, check your mail inbox to reset your password.',
    'We regret to inform you that your account has been suspended.':'We regret to inform you that your account has been suspended.',
    'Account Suspended':'Account Suspended',
    'Admin panel':'Admin panel',
    'Please enter your credentials...':'Please enter your credentials..',
    'Please enter your credentials..':'Please enter your credentials..',
    'signup':'signup',
    'First name':'First name',
    'Family name':'Family name',
    'Confirm password':'Confirm password',
    'Clients':'Clients',
    'Home':'Home',
    'Settings':'Settings',
    'Net profit':'Net profit',
    'Total Profit':'Total Profit',
    'Balance':'Balance',
    'History of SMS messages':'History of SMS messages',
    'History of account balance recharging':'History of account balance recharging',
    'Sender ID':'Sender ID',
    'This month':'This month',
    'Total Consumed':'Total Consumed',
    'with profit':'with profit',
    'sent':'sent',
    'Operators':'Operators',
    'We will be back soon!':'We will be back soon!',
    'This page is currently under development':'This page is currently under development',
    'Create':'Create',
    'Clients management':'Clients management',
    'Delete client':'Delete client',
    'Are you sure you want to delete this account? This action is irreversible, and all the client data will be permanently deleted.':'Are you sure you want to delete this account? This action is irreversible, and all the client data will be permanently deleted.',
    'Create a new client':'Create a new client',
    'Client details':'Client details',
    'Phone number':'Phone number',
    'Login credentials':'Login credentials',
    'Generate':'Generate',
    'Send login credantials to this email.':'Send login credantials to this email.',
    'Add sold':'Add sold',
    'You can add sold easily from here.':'You can add sold easily from here.',
    'Sold':'Sold',
    'Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host..':'Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host..',
    'Ban client':'Ban client',
    'Are you sure you want to ban this account? The client will lose access to their account.':'Are you sure you want to ban this account? The client will lose access to their account.',
    'Ban in only 3 days':'Ban in only 3 days',
    'Ban':'Ban',
    'Delete SMTP config':'Delete SMTP config',
    'Are you sure you want to delete this SMTP config? This action is irreversible.':'Are you sure you want to delete this SMTP config? This action is irreversible.',
    'Delete sender id':'Delete sender id',
    'Are you sure you want to delete this sender id? This action is irreversible.':'Are you sure you want to delete this sender id? This action is irreversible.',
    'loading':'loading',
    'Client Subscription':'Client Subscription',
    'Client subscription and management':'Client subscription and management',
    'Manage Subscription':'Manage Subscription',
    'days left':'days left',
    'You can edit subscription days':'You can edit subscription days',
    'Days':'Days',
    'You can edit or delete accounts':'You can edit or delete accounts',
    'email per hour':'email per hour',
    'Sold Left':'Sold Left',
    'SMS Configuration':'SMS Configuration',
    'Client can use his own Sender id':'Client can use his own Sender id',
    'Sender id':'Sender id',
    'SMS Statistiques':'SMS Statistiques',
    'NIC':'NIC',
    'subscription':'subscription',
    'Email settings':'Email settings',
    'SMS settings':'SMS settings',
    'History':'History',
    'Additional informations':'Additional informations',
    'Name':'Name',
    'Location':'Location',
    'Website':'Website',
    'Mail':'Mail',
    'Phone':'Phone',
    'Bank account':'Bank account',
    'Postal account':'Postal account',
    'Commercial Register No':'Commercial Register No',
    'AI':'AI',
    'NIF':'NIF',
    'client name':'client name',
    'ban':'ban',
    'unban':'unban',
    'unbanning':'unbanning',
    'back':'back',
    'Reset your password to be able to connect again to your account':'Reset your password to be able to connect again to your account',
    'we offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.':'we offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.',
    'SignUp':'SignUp',
    'Email confirmation failed!':'Email confirmation failed!',
    'Reset':'Reset',
    'internal server error':'internal server error',
    'Internal server error':'Internal server error',
    'Session ended':'Session ended',
    'session ended':'session ended',
    'An unexpected error has occured':'An unexpected error has occured',
    'Item added successfuly':'Item added successfully',
    'Sending now! For updates, visit':'Sending now! For updates, visit',
    'reporting page':'reporting page',
    'This message added successfuly to your Message scheduling list':'This message added successfully to your Message scheduling list',
    'For a better experience, keep the message under 320 characters.':'For a better experience, keep the message under 320 characters.',
    'Last name':'Last name',
    'Full name':'Full name',
    'Groups':'Groups',
    'Action':'Action',
    'Exported successfuly':'Exported successfully',
    'No item is checked':'No item is checked',
    'Saved successfully':'Saved successfully',
    'No item is selected, choose in minimum one item':'No item is selected, choose in minimum one item',
    'Ok':'Ok',
    'Are you sure you want to delete this items':'Are you sure you want to delete this items',
    'Items deleted successfully':'Items deleted successfully',
    'of':'of',
    'items Added successfully':'items Added successfully',
    'Item updated successfuly':'Item updated successfully',
    'Deleted successfuly':'Deleted successfully',
    'Are you sure you want to delete this item ?':'Are you sure you want to delete this item ?',
    'Created in':'Created in',
    'deleted_contact':'deleted_contact',
    'Date':'Date',
    'RESEND':'RESEND',
    'failed':'failed',
    'Description':'Description',
    'Item deleted successfully':'Item deleted successfully',
    'Error: There was an error processing your request':'Error: There was an error processing your request',
    'Service Unavailable: The server is currently unable to handle the request':'Service Unavailable: The server is currently unable to handle the request',
    'Internal Server Error: The server has encountered a situation it does not know how to handle':'Internal Server Error: The server has encountered a situation it does not know how to handle',
    'Too Many Requests: The user has sent too many requests in a given amount of time':'Too Many Requests: The user has sent too many requests in a given amount of time',
    'Method Not Allowed: The request method is not supported for the requested resource':'Method Not Allowed: The request method is not supported for the requested resource',
    'Not Found: The server cannot find the requested resource':'Not Found: The server cannot find the requested resource',
    'Forbidden: The server understood the request but refuses to authorize it':'Forbidden: The server understood the request but refuses to authorize it',
    'Unauthorized: Authentication is required, and the user has not provided valid credentials':'Unauthorized: Authentication is required, and the user has not provided valid credentials',
    'Bad Request: The server cannot process the request due to an invalid syntax':'Bad Request: The server cannot process the request due to an invalid syntax',
    'Saved successfuly':'Saved successfully',
    'Too many requests':'Too many requests',
    'There is no selected file':'There is no selected file',
    'Cannot uplaod more than 10 files':'Cannot uplaod more than 10 files',
    'Are you sure you want to delete this item':'Are you sure you want to delete this item',
    'List':'List',
    'Black List':'Black List',
    'Receiver':'Receiver',
    'Created At':'Created At',
    'Edit':'Edit',
    'File uploaded successfully':'File uploaded successfully',
    'You must upload a csv file only':'You must upload a csv file only',
    'file upload failed':'file upload failed',
    'already exist.':'already exist.',
    'Exported successfully':'Exported successfully',
    'No contact is selected':'No contact is selected',
    'Item added successfully':'Item added successfully',
    'Item updated successfully':'Item updated successfully',
    'Updated successfully':'Updated successfully',
    'found':'found',
    'created':'created',
    'Lists':'Lists',
    'Removed from list':'Removed from list',
    'Item listed successfuly':'Item listed successfuly',
    'Added to list':'Added to list',
    'Deleted successfully':'Deleted successfully',
    'New config added':'New config added',
    'Applyed successfully':'Applyed successfully',
    'Receivers ( lists )':'Receivers ( lists )',
    'Send at':'Send at',
    'Process':'Process',
    'Emails':'Emails',
    'Pending':'Pending',
    'Opened':'Opened',
    'Impression':'Impression',
    'Bounced':'Bounced',
    'Deleting':'Deleting',
    'Count':'Count',
    'Email canceled successfully':'Email canceled successfully',
    'Login out..':'Login out..',
    'No item is selected':'No item is selected',
    'Client':'Client',
    'Days left':'Days left',
    'Expiration date':'Expiration date',
    'Expired':'Expired',
    'Active':'Active',
    'Banned':'Banned',
    'Actions':'Actions',
    'Client deleted successfully':'Client deleted successfully',
    'Client banned successfully':'Client banned successfully',
    'Client Unbanned successfully':'Client Unbanned successfully',
    'Subscription days changed successfully':'Subscription days changed successfully',
    'Sold added successfully':'Sold added successfully',
    'Limit changed successfully':'Limit changed successfully',
    'Smtp account deleted successfully':'Smtp account deleted successfully',
    'SenderId created successfully':'SenderId created successfully',
    'Actor':'Actor',
    'Consumed':'Consumed',
    'Price':'Price',
    'Passwords are not the same.':'Passwords are not the same.',
    'email or password are wrong':'email or password are wrong',
    'connected successfully':'connected successfully',
    'password field is empty':'password field is empty',
    'email is not valid':'email is not valid',
    'email field is empty':'email field is empty',
    'Password should be at least 8 characters long':'Password should be at least 8 characters long',
    'Email is required':'Email is required',
    'Email already exist':'Email already exist',
    'Please enter a valid email':'Please enter a valid email',
    'Please enter a valid phone number':'Please enter a valid phone number',
    'Please enter a DZ phone number':'Please enter a DZ phone number',
    "Society name can't be more than 80 letters":"Society name can't be more than 80 letters",
    "Family name can't be more than 80 letters":"Family name can't be more than 80 letters",
    "First name can't be more than 80 letters":"First name can't be more than 80 letters",
    'New user created successfully':'New user created successfully',
    'New user created successfully, but an unexpected error has occured while sending the email':'New user created successfully, but an unexpected error has occured while sending the email',
    'A new client account created':'A new client account created',
    'Password is required':'Password is required',
    'Password should contain at least one special character':'Password should contain at least one special character',
    'Password should contain at least one digit':'Password should contain at least one digit',
    'Password should contain at least one lowercase letter':'Password should contain at least one lowercase letter',
    'Password should contain at least one uppercase letter':'Password should contain at least one uppercase letter',
    'The changes have been saved successfully':'The changes have been saved successfully',
    'Client information changed':'Client information changed',
    "Client not found, refresh the page and try again.":"Client not found, refresh the page and try again.",
    'Smtp account not found, refresh the page and try again.':'Smtp account not found, refresh the page and try again.',
    'Sold value must be a number':'Sold value must be a number',
    'Client not found, refresh the page and try again':'Client not found, refresh the page and try again',
    'Days value must be a number':'Days value must be a number',
    'SenderId not found, refresh the page and try again':'SenderId not found, refresh the page and try again',
    'Sender id account not found, refresh the page and try again.':'Sender id account not found, refresh the page and try again.',
    'Enter a valid amount':'Enter a valid amount',
    'SenderId is required':'SenderId is required',
    'This senderId already exist for another client':'This senderId already exist for another client',
    'SenderId already exist':'SenderId already exist',
    'Phone number already exist':'Phone number already exist',
    'Enter a valid phone number':'Enter a valid phone number',
    'Group name already exist':'Group name already exist',
    'Number only':'Number only',
    'String only':'String only',
    'Phone number is required':'Phone number is required',
    'Enter a valid society name':'Enter a valid society name',
    'Society is required':'Society is required',
    'Enter a valid name':'Enter a valid name',
    'Group name is required':'Group name is required',
    'An error has occured, refresh your page and try again.':'An error has occured, refresh your page and try again.',
    'Sending messages between 10pm and 7am is not allowed. Please try again later.':'Sending messages between 10pm and 7am is not allowed. Please try again later.',
    'Message cannot exceed 1600 characters':'Message cannot exceed 1600 characters',
    'Message is required':'Message is required',
    'Contacts are required':'Contacts are required',
    'Subject is required':'Subject is required',
    'Max lines reached (1000 per time)':'Max lines reached (1000 per time)',
    'Phone number is obligated':'Phone number is obligated',
    'Date is not valid':'Date is not valid',
    'Select a valid hour':'Select a valid hour',
    'Hour is required':'Hour is required',
    'Repeted is required':'Repeted is required',
    'Date is required':'Date is required',
    'Message not found':'Message not found',
    'Username is required':'Username is required',
    'Source Already exist':'Source Already exist',
    'Valid source only':'Valid source only',
    'Source is required':'Source is required',
    'Valid password only':'Valid password only',
    'Valid username only':'Valid username only',
    'Group not found':'Group not found',
    'Name field is required':'Name field is required',
    'Group is already created':'Group is already created',
    'An error has occured while uploading your image':'An error has occured while uploading your image',
    'Image only':'Image only',
    'Large image size':'Large image size',
    'Max upload files reached':'Max upload files reached',
    'Email or password are wrong':'Email or password are wrong',
    'Connected successfully':'Connected successfully',
    'Email already taken':'Email already taken',
    'Email is not valid':'Email is not valid',
    'The requested item was not found. We apologize for the inconvenience.':'The requested item was not found. We apologize for the inconvenience.',
    'This link is not valid anymore, try to signup again.':'This link is not valid anymore, try to signup again.',
    'The requested item is not valid anymore, try to signup again.':'The requested item is not valid anymore, try to signup again.',
    'reloaded.':'reloaded.',
    'Your email is verified':'Your email is verified',
    'The requested item is not valid anymore, try to again later.':'The requested item is not valid anymore, try to again later.',
    'reloaded':'reloaded',
    'The link is verified':'The link is verified',
    'The requested item is not valid anymore, try again later.':'The requested item is not valid anymore, try again later.',
    'This link is not valid anymore, try again later.':'This link is not valid anymore, try again later.',
    'This link is not valid anymore, try again later':'This link is not valid anymore, try again later',
    'Check your inbox':'Check your inbox',
    'This session is not valid anymore, try signup again later.':'This session is not valid anymore, try signup again later.',
    'Password saved successfully':'Password saved successfully',
    "Passwords don't match":"Passwords don't match",
    'Password confirmation is required':'Password confirmation is required',
    'Enter a valid password':'Enter a valid password',
    'Your information saved':'Your information saved',
    'Enter a valid family name':'Enter a valid family name',
    'Family name is required':'Family name is required',
    'Name is required':'Name is required',
    'Logged out successfully':'Logged out successfully',
    'Incorrect password':'Incorrect password',
    'Password changed successfully':'Password changed successfully',
    'New password is required':'New password is required',
    "Email Already exist":"Email Already exist",
    'Enter a valid email':'Enter a valid email',
    'This title is already used':'This title is already used',
    'Title is required':'Title is required',
    'Valid title only':'Valid title only',
    'Group does not exist':'Group does not exist',
    'Name of group is required':'Name of group is required',
    'Group already exists':'Group already exists',
    'Not Found':'Not Found',
    'Contact is in the list':'Contact is in the list',
    'List not found':'List not found',
    'Already exists, try make some changes.':'Already exists, try make some changes.',
    'Server port is required':'Server port is required',
    'Please enter a valid server port':'Please enter a valid server port',
    'Server host is required':'Server host is required',
    'Please enter a valid server host':'Please enter a valid server host',
    'Please enter a valid SMTP password':'Please enter a valid SMTP password',
    'Smtp password is required':'Smtp password is required',
    'Please enter a valid SMTP username':'Please enter a valid SMTP username',
    'Smtp username is required':'Smtp username is required',
    'SMTP not found':'SMTP not found',
    'Can not be more than 500 per hour':'Can not be more than 500 per hour',
    'Limit is required':'Limit is required',
    'There is no SMTP mentioned':'There is no SMTP mentioned',
    'The code is not valid anymore, refresh and try again.':'The code is not valid anymore, refresh and try again.',
    'Invalid Code. Please check your code and try again.':'Invalid Code. Please check your code and try again.',
    'This Smtp Already exists, try make some changes.':'This Smtp Already exists, try make some changes.',
    'The code is not valid anymore, Max attempt reached.':'The code is not valid anymore, Max attempt reached.',
    'The code is not valid anymore, try again later.':'The code is not valid anymore, try again later.',
    'Enter a valid code':'Enter a valid code',
    'Valid host only':'Valid host only',
    'Check your configs and try again.':'Check your configs and try again.',
    'Maximum emails reached. Your emails will be queued and sent as soon as possible.':'Maximum emails reached. Your emails will be queued and sent as soon as possible.',
    'You cannot send more than 10 files':'You cannot send more than 10 files',
    'Name is too large':'Name is too large',
    'Enter valid email':'Enter valid email',
    'Your page is empty, please add something.':'Your page is empty, please add something.',
    'Please select a mailing list':'Please select a mailing list',
    'Please select an email':'Please select an email',
    'Config does not exist, try refresh your page.':'Config does not exist, try refresh your page.',
    'Please select a value':'Please select a value',
    'Please select the time':'Please select the time',
    'Please select a date':'Please select a date',
    'your page is empty, please add something.':'your page is empty, please add something.',
    'Valid email only':'Valid email only',
    'Email not found, refresh your page and try again.':'Email not found, refresh your page and try again.',
    'Maximum emails reached. Some emails will be queued and sent as soon as possible.':'Maximum emails reached. Some emails will be queued and sent as soon as possible.',
    'Emails are currently being sent, This may take some time.':'Emails are currently being sent, This may take some time.',
    'Invalid base64 string format':'Invalid base64 string format',
    'Email not found, refresh your page.':'Email not found, refresh your page.',
    'Email scheduled successfully':'Email scheduled successfully',
    'Mailing list is required':'Mailing list is required',
    'Email already exists':'Email already exists',
    'Invalid email':'Invalid email',
    'Connection timed out':'Connection timed out',
    'Not connected to mail server':'Not connected to mail server',
    'Not connected to mail server (error)':'Not connected to mail server (error)',
    'Item not found':'Item not found',
    'File not found':'File not found',
    'Only PDF, PNG, JPEG, JPG files are allowed':'Only PDF, PNG, JPEG, JPG files are allowed',
    'The maximum allowed file size is 10 MB':'The maximum allowed file size is 10 MB',
    'Client not found':'Client not found',
    'Unable to send, set your SMS account config first':'Unable to send, set your SMS account config first',
    'Insufficient credit, Recharge it and try again later.':'Insufficient credit, Recharge it and try again later.',
    'Insufficient credit, Recharge it and try again later':'Insufficient credit, Recharge it and try again later',
    'Account subscription has expired':'Account subscription has expired',
    'email-field-is-empty':'Email is required',
    'password-field-is-empty':'Password is required',
    'email-is-not-valid':'Please enter a valid email',
    'Resend Message':'Resend Message',
    '':'',
    'Save your information':'Save your information',
    'Save password':'Save password',
    'Amount (DZD)':'Amount (DZD)',
    'Last balance (DZD)':'Last balance (DZD)',
    'New balance (DZD)':'New balance (DZD)',
    "Balance added can't be edited or deleted, click add button to add balance":"Balance added can't be edited or deleted, click add button to add balance",
    'Add balance':'Add balance',
    'Amount':'Amount',
    'insufficient credit, Recharge it and try again.':'insufficient credit, Recharge it and try again.',
    'An error occurred by the service provider':'An error occurred by the service provider',
    'Message not sent because the user is banned.':'Message not sent because the user is banned.',
    'Message does not exist.':'Message does not exist.',
    'Contact does not exist.':'Contact does not exist.',
    'create account':'create account',
    '':'',
    '':'',
    '':'',
    '':'',
    '':'',

}



export default english;
