import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './components/login';
import Signup from './components/signup';
import React, { useState } from 'react';
import ConfirmEmail from './components/confirmEmail';
import InitInfo from './components/init_account';
import Home from './components/home';
import SMSMarketing from './components/serviceSMS/SMSMarketing';
import './App.css'
import './App.css'
import Hello from './components/hello';
import Contacts from './components/contacts/home';
import Editor from './components/editor';
import EmailMarketing from './components/serviceEmail/EmailMarketing';
import Button from '@mui/material/Button';
import ChatIcon from '@mui/icons-material/Chat';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ForgotPassword from './components/forgot_password';
import CheckResetPassword from './components/checkResetPassword';
import NotFound from './components/404';
import styled from 'styled-components'
import Feedback from './components/feedback';
import Admin from './components/admin/admin';
import LoginAdmin from './components/admin/loginAdmin';
import Banned from './components/banned';
import english from './languages/en';
import french from './languages/fr';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Title = styled.h1`
    font-size: large;
    color: #0f0b0e ;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 30px;
`


function App() {
  const [url, setUrl] = useState('localhost:3000')

  // send feedback
  const [open, setOpen] = useState(false);

  const handleClickOpenUserModal = () => {
      setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [ openAlert, setOpenAlert] = useState(false)
  


  // conditions and terms
  const [openTermsModal, setOpenTermsModal] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openTermsModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openTermsModal]);


  React.useEffect(() => {
    const language = localStorage.getItem('language');
    if(language === 'english' || language === 'french'){
      changeLanguage(language)
    }
  }, []);



  const [languageData, setLanguageData] = useState(english);
  const [languageSelected, setLanguageSelected] = useState("english");
  function changeLanguage(language){
    if(language === "english"){
      setLanguageData(english)
      setLanguageSelected('english')
      localStorage.setItem('language', language);
    }else if(language === "french"){
      setLanguageData(french)
      setLanguageSelected('french')
      localStorage.setItem('language', language);
    }else{
      setLanguageData(english)
      setLanguageSelected('english')
      localStorage.setItem('language', language);
    }
  }

  const language = new Proxy(languageData, {
    get: (target, prop) => {
      // console.log(prop)
      // console.log(typeof prop)
      // console.log(typeof prop === 'string')
      if(prop !== '[object Object]'){
        return prop in target ? target[prop] : prop;
      }else{
        return undefined
      }
    }
});


  return (
    <Router style={{position: 'relative'}}>
      <Routes>
        {/* <Route axact path="/*" element={ < Panel /> } ></Route> */}
        {/* <Route axact path="/body" element={ < Body /> } ></Route> */}
        <Route axact path="/" element={ < Hello Language={language}  /> } url={url} ></Route>
        <Route axact path="/login" element={ < Login Language={language} setOpenTermsModal={setOpenTermsModal} /> } ></Route>
        {/* <Route axact path="/signup" element={ < Signup setOpenTermsModal={setOpenTermsModal} /> } url={url} ></Route> */}
        <Route axact path="/forgot_password" element={ < ForgotPassword Language={language}  setOpenTermsModal={setOpenTermsModal} /> } url={url} ></Route>
        {/* <Route axact path="/confirm_email" element={ < ConfirmEmail  setOpenTermsModal={setOpenTermsModal}  /> } url={url} ></Route> */}
        <Route axact path="/check_reset_password" element={ < CheckResetPassword Language={language}   setOpenTermsModal={setOpenTermsModal} /> } url={url} ></Route>
        {/* <Route axact path="/init_info" element={ < InitInfo  setOpenTermsModal={setOpenTermsModal} /> }  ></Route> */}
        <Route axact path="/app/*" element={ < Home Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected} /> }  ></Route>
        <Route axact path="/app/SMSMarketing/*" element={ < SMSMarketing Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected}  /> }  ></Route>
        <Route axact path="/app/EmailMarketing/*" element={ < EmailMarketing Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected} /> }  ></Route>
        <Route axact path="/app/Contacts/" element={ < Contacts Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected} /> }  ></Route>
        <Route axact path="/app/banned/*" element={ < Banned Language={language} /> }  ></Route>
        {/* <Route axact path="/app/Profile/*" element={ < Profile /> }  ></Route> */}
        {/* <Route axact path="/condition" element={ < Condition /> }  ></Route> */}
        {/* <Route axact path="/editor" element={ < Editor /> }  ></Route> */}
        <Route axact path="/admin/login" element={ < LoginAdmin Language={language}  /> }  ></Route>
        <Route axact path="/admin/*" element={ < Admin Language={language} changeLanguage={changeLanguage} languageSelected={languageSelected}  /> }  ></Route>
        <Route axact path="/*" element={ < NotFound /> }  ></Route>
      </Routes>
      {/* <div style={{position:'absolute', bottom: '10px', right: '10px', margin: '0px', padding: '5px 10px', opacity: '0.7', backgroundColor: '#219ebc', color: "white", borderRadius: "3px", boxShadow: '0px 0px 3px 0px gray'}}>
          <p style={{margin: '0px'}}>Version beta <IconButton onClick={() => handleClickOpenUserModal()} size='small' style={{color: 'white'}}><ChatIcon /></IconButton></p>
      </div> */}

      {/* //beta feedback  modal */}
      {/* <Feedback open={open} setOpen={setOpen}></Feedback> */}
      
       {/* // snackbar */}
       <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
          {language[alertMessage] ? language[alertMessage] : alertMessage}

          </Alert>
        </Snackbar>


        {/* terms & conditions */}
        <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
    
          <Title>User registration</Title>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
              

          <Title>Terms of use</Title>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
    

          <Title>Delete Contacts</Title>
          <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et</p>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTermsModal}>Close</Button>
        </DialogActions>
      </Dialog>

    </Router>
  );
}

export default App;
