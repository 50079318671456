import React, {useEffect, useState} from 'react'
import axios from 'axios';
import styled from 'styled-components'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
//import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from './Loading';
import AlertComp from './alert';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getResponseMessage } from './responding';
import colors from '../colors';



const MyLink = styled(Link)`
    //text-decoration-line: none;
    color: black;
`

const Body = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: row;
`;

const BodyLogin = styled.div`
  width: 50%;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 900px) {
      width: 100% ;
      
    }
`;
const BodyLoginContent = styled.div`
  width: 60%;
  min-height: 300px;
  height: 100vh;
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-top: 20%;
  max-width: 400px;
  padding: 10px;
  @media only screen and (max-width: 900px) {
      width: 80% ;
      margin-top: 20%;
      background-color: white;
    }
`;

const BodyImage = styled.div`
  width: 42%;
  padding: 15px 4%;
  height: calc(100vh - 30px);
  margin: 0px 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center ;
  //background-image: url("/assets/img/login6.png") ;
  background-position: center;
  background-size: cover;
  background-color: ${colors.primary};
  @media only screen and (max-width: 900px) {
      display:none ;
    }
`;


const Title = styled.h1`
  font-size: x-large;
  padding: 0px 0px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 0%;
`;

const Paragr = styled.p`
  margin: 0%;
  padding: 10px 0px;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  width: 120px;
  height: auto;
  align-self: center;
  
`;



export default function CheckResetPassword({setOpenTermsModal, Language}) {

  const [ loading, setLoading] = useState(true)
  const [ verified, setVerified] = useState(false)
  const [ error, setError] = useState('')
  const [ password, setPassword] = useState('')
  const [ confirmPassword, setConfirmPassword] = useState('')
  const [ isErrorPassword, setIsErrorPassword] = useState(false)
  const [ isErrorConfirmPassword, setIsErrorConfirmPassword] = useState('')
  const [ PasswordError, setPasswordError] = useState(false)
  const [ confirmPasswordError, setConfirmPasswordError] = useState('')
  const [ alertMessage, setAlertMessage] = useState('')
  const [ alertType, setAlertType] = useState('')
  const [btnContent, setBtnContent] = useState('Reset')
  const navigate = useNavigate()
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  function createPassword(password, confirmPassword){
    setBtnContent(<CircularProgress style={{color: 'white', width: '30px', height:'30px'}}></CircularProgress>)
    setDisabledBtn(true)
    axios.post("/reset_password", {
      password, confirmPassword
    })
    .then(function (response) {
      setDisabledBtn(false)
      setBtnContent('Reset')
      console.log(response)
      if(response.data.result === 'done'){
        setOpen(true)
        setAlertMessage(response.data.message)
        setAlertType("success")
        setTimeout(() => {
          navigate('/init_info', { replace: true });
        }, 1000);
      }
      else if(response.data.result === 'input_error'){
        for(let i = 0; i < response.data.error.length; i++){
          if(response.data.error[i].path === 'password'){
            setIsErrorPassword(true)
            setPasswordError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
          }
          else{
            setIsErrorConfirmPassword(true)
            setConfirmPasswordError(Language[response.data.error[i].error] ? Language[response.data.error[i].error] : response.data.error[i].error)
          }
        }
      }
      else{
        setOpen(true)
        setAlertMessage(response.data.message)
        setAlertType("error")
      }
    })
    .catch(function (error) {
      setBtnContent('Reset')
      setDisabledBtn(false)
      // handle error
      console.log(error);
      if(error.response){
        setOpen(true)
        setAlertMessage(getResponseMessage(error.response.status))
        setAlertType("error")
      }else{
        setOpen(true)
        setAlertMessage('internal server error')
        setAlertType("error")
      }
    })
  }

useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search)
  const id = queryParams.get("id")
  const code = queryParams.get("code")  
  // get data from database
  axios.post("/check_reset_password", {
    id, code
  })
  .then(function (response) {
    if(response.data.done === true){
      setLoading(false)
      setVerified(true)
      setOpen(true)
      setAlertMessage(response.data.message)
      setAlertType('success')
    }else if(response.data.done === 'reloaded'){
      setLoading(false)
      setVerified(true)
    }else if(response.data.done === 'redirect'){
      navigate('/App', { replace: true });
    }else{
      setLoading(false)
      setVerified(false)
      setError(response.data.message)
    }
    console.log(response.data)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
    if(error.response){
      setOpen(true)
      setAlertMessage(getResponseMessage(error.response.status))
      setAlertType("error")
    }else{
      setOpen(true)
      setAlertMessage('Internal server error')
      setAlertType("error")
    }
  })

}, [])


if(loading){
  return(
    <Loading/>
  )
}else if(!loading && !verified){
  return(
    <Body>

    <BodyLogin>
        <BodyLoginContent>
            <Logo src={"./assets/img/logo192.png"} />
            
            <Title>{Language['Email confirmation failed!']}</Title>
            <Paragr textAlign={"right"} style={{width: "100%", textAlign: 'left', color: 'grey'}} >{Language[error] ? Language[error] : error}</Paragr>
            <Button variant="contained" disableElevation style={{marginTop: '10px', backgroundColor: colors.primary}} href={"/signup"}>
            {Language['SignUp']}
            </Button>
        </BodyLoginContent>
    </BodyLogin>
    <BodyImage>
      <Logo style={{alignSelf: 'center', width: '70px'}} src={"./assets/img/logo192white.png"} />
      <div style={{justifyContent: 'center'}}>
        <Title style={{color: 'white', fontSize: '40px', textAlign: 'left', width: '100%', marginTop: '0px'}}>{Language['Let us help you grow your business']}</Title>
        <Paragr style={{color: '#ffffffcc', fontSize: '17px', width: "100%"}}>{Language['we offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.']}</Paragr>
      </div>
      <>
      <Paragr style={{color: 'white', fontSize: '15px', width: "100%", textAlign:'center', margin: 0}}><MyLink style={{color: "white"}} to="https://linksy.tech/terms-and-conditions/" >{Language['Our Conditions & Terms.']}</MyLink></Paragr>
      </>
    </BodyImage>
  </Body>
  )

}else{
  return (
    
    <Body>

        <BodyLogin>
          
            <BodyLoginContent>
                <Logo src={"./assets/img/logo192.png"} />
                
                <Title>{Language['Reset your password']}</Title>
                <Paragr textAlign={"right"} style={{width: "100%", textAlign: 'left', color: 'grey'}} >{Language['Reset your password to be able to connect again to your account']}</Paragr>

                <FormControl error={isErrorPassword} variant="standard">
                    <InputLabel htmlFor="password_input">{Language['Password']}</InputLabel>
                    <Input 
                    onKeyDown={ (event) => { if (event.key === 'Enter') { createPassword(password, confirmPassword)}}}
                    onChange={ (e) => {
                      setPassword(e.target.value)
                      setIsErrorPassword(false)
                      setPasswordError('')
                    }}
                    type='password'
                    id="password_input"
                    defaultValue=""
                    aria-describedby="password_input_error"
                    />
                    <FormHelperText id="password_input_error"> {PasswordError} </FormHelperText>                   
                </FormControl>

                <FormControl error={isErrorConfirmPassword} variant="standard">
                    <InputLabel htmlFor="confirm_password_input">{Language['Confirm password']}</InputLabel>
                    <Input
                    onKeyDown={ (event) => { if (event.key === 'Enter') { createPassword(password, confirmPassword)}}}
                    onChange={ (e) => {
                      setConfirmPassword(e.target.value)
                      setIsErrorConfirmPassword(false)
                      setConfirmPasswordError('')
                    }}
                    type='password'
                    id="confirm_password_input"
                    defaultValue=""
                    aria-describedby="confirm_password_input_error"
                    />
                    <FormHelperText id="confirm_password_input_error"> {confirmPasswordError} </FormHelperText>
                </FormControl>

                <Button variant="contained" disableElevation disabled={disabledBtn} style={{marginTop: '10px', backgroundColor: colors.primary}} onClick={() => createPassword(password, confirmPassword)} >
                  {Language[btnContent] ?  Language[btnContent] : btnContent}
                </Button>  
                <AlertComp Language={Language} open={open} handleClose={handleClose}  message={alertMessage} type={alertType} />         
            </BodyLoginContent>
        </BodyLogin>
        <BodyImage>
          <Logo style={{alignSelf: 'center', width: '70px'}} src={"./assets/img/logo192white.png"} />
          <div style={{justifyContent: 'center'}}>
            <Title style={{color: 'white', fontSize: '40px', textAlign: 'left', width: '100%', marginTop: '0px'}}>{Language['Let us help you grow your business']}</Title>
            <Paragr style={{color: '#ffffffcc', fontSize: '17px', width: "100%"}}>{Language['we offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.']}</Paragr>
          </div>
          <>
          <Paragr style={{color: 'white', fontSize: '15px', width: "100%", textAlign:'center', margin: 0}}><MyLink style={{color: "white"}} to="https://linksy.tech/terms-and-conditions/" >{Language['Our Conditions & Terms.']}</MyLink></Paragr>
          </>
        </BodyImage>
    </Body>
  )
}
 
}
