const french = {
    "Welcome": "Bienvenue",
    "to Linksy Platform": "à la plateforme Linksy",
    "The best Marketing tool in Algeria!": "Le meilleur outil de marketing en Algérie !",
    "Expired in": "Expiré dans",
    "Email Marketing": "Marketing par e-mail",
    "Contacts": "Contacts",
    "SMS Marketing": "Marketing par SMS",
    "Logout": "Déconnexion",
    "My account": "Mon compte",
    "Configuration": "Configuration",
    "Reporting": "Rapports",
    "Contacts list": "Liste des contacts",
    "set my SMS Account!": "configurer mon compte SMS !",
    "Unable to send message before setting your SMS account": "Impossible d'envoyer un message avant de configurer votre compte SMS",
    "Sender": "Expéditeur",
    "Create Group": "Créer un groupe",
    "no option": "pas d'option",
    "Create Contact": "Créer un contact",
    "Show contacts by groups": "Afficher les contacts par groupes",
    "Message": "Message",
    "Please note that messages containing Arabic characters will be counted as one message if they are 70 characters or less. Messages without any Arabic characters will be counted as one message if they are 160 characters or less.": "Veuillez noter que les messages contenant des caractères arabes seront comptés comme un message s'ils contiennent 70 caractères ou moins. Les messages sans aucun caractère arabe seront comptés comme un message s'ils contiennent 160 caractères ou moins.",
    "Characters": "Caractères",
    "programme": "programmer",
    "send": "envoyer",
    "Pragramme a message": "Programmer un message",
    "Fill the field below then press programme, this action will send your message at the selected time.": "Remplissez le champ ci-dessous puis appuyez sur programmer, cette action enverra votre message à l'heure sélectionnée.",
    "Select date": "Sélectionner une date",
    "Time": "Heure",
    "Repeted": "Répété",
    "None": "Aucun",
    "monthly": "mensuel",
    "yearly": "annuel",
    "Cancel": "Annuler",
    "Create New Contact": "Créer un nouveau contact",
    "This is a quick way to create a contact and assemble it in a new group.": "Ceci est un moyen rapide de créer un contact et de l'assembler dans un nouveau groupe.",
    "Group Name": "Nom du groupe",
    "Contact": "Contact",
    "fill the field below then press create, this action will create a new contact according to this information.": "remplissez le champ ci-dessous puis appuyez sur créer, cette action créera un nouveau contact selon ces informations.",
    "create": "créer",
    "society": "société",
    "First Name": "Prénom",
    "Family Name": "Nom de famille",
    "Phone Number": "Numéro de téléphone",
    "email": "email",
    "add new contact": "ajouter un nouveau contact",
    "Add": "Ajouter",
    "import": "importer",
    "All groups": "Tous les groupes",
    "delete contacts": "supprimer les contacts",
    "export contacts": "exporter les contacts",
    "group contacts": "grouper les contacts",
    "Edit Groups": "Modifier les groupes",
    "You can easily delete a group definitly by clicking on the trash icon.": "Vous pouvez facilement supprimer un groupe définitivement en cliquant sur l'icône de la poubelle.",
    "contacts": "contacts",
    "Done": "Fait",
    "edit groups": "modifier les groupes",
    "Select a group": "Sélectionner un groupe",
    "Select one of the previous group to add the selected contacts in one group.": "Sélectionnez l'un des groupes précédents pour ajouter les contacts sélectionnés dans un groupe.",
    "Group": "Groupe",
    "none": "aucun",
    "or": "ou",
    "Create a new group": "Créer un nouveau groupe",
    "Title": "Titre",
    "Save": "Enregistrer",
    "save": "enregistrer",
    "Import New Contacts": "Importer de nouveaux contacts",
    "file": "fichier",
    "text": "texte",
    "import new contacts by uploading a csv file": "importer de nouveaux contacts en téléchargeant un fichier csv",
    "import new contacts by copying & pasting lines": "importer de nouveaux contacts en copiant et collant des lignes",
    "Click or drag file to this area to upload": "Cliquez ou faites glisser un fichier dans cette zone pour le télécharger",
    "Support for a single or bulk upload. Strictly prohibit from uploading company data or other": "Support pour un téléchargement unique ou en masse. Strictement interdit de télécharger des données d'entreprise ou autres",
    "Creating and adding in a group": "Créer et ajouter dans un groupe",
    "Add imported contacts to a group": "Ajouter les contacts importés à un groupe",
    "group": "groupe",
    "Import": "Importer",
    "to import your contacts easily.": "pour importer vos contacts facilement.",
    "CSV template": "modèle CSV",
    "configrations of the imported file.": "configurations du fichier importé.",
    "last step before uploading your contacts, choose the right columns then click import": "dernière étape avant de télécharger vos contacts, choisissez les bonnes colonnes puis cliquez sur importer",
    "update existing items according the phone number": "mettre à jour les éléments existants selon le numéro de téléphone",
    "Society": "Société",
    "Email": "Email",
    "Delete Contact": "Supprimer le contact",
    "delete": "supprimer",
    "ok": "ok",
    "Update Contact": "Mettre à jour le contact",
    "fill the field below then press update, this action will update this contact according to this information.": "remplissez le champ ci-dessous puis appuyez sur mettre à jour, cette action mettra à jour ce contact selon ces informations.",
    "update": "mettre à jour",
    "message": "message",
    "scheduled": "programmé",
    "balance": "solde",
    "total message sent": "total des messages envoyés",
    "Search": "Rechercher",
    "messages found": "messages trouvés",
    "Credit available": "Crédit disponible",
    "total costs": "coût total",
    "e.g. \"names\", \"senders\", \"phone numbers\"..": "ex. \"noms\", \"expéditeurs\", \"numéros de téléphone\"..",
    "Filter": "Filtrer",
    "Cancel Filter": "Annuler le filtre",
    "Export": "Exporter",
    "SMS": "SMS",
    "Life Time": "Durée de vie",
    "advanced": "avancé",
    "this year": "cette année",
    "this month": "ce mois",
    "This Month": "Ce mois-ci",
    "This Year": "Cette année",
    "Advanced Filtering": "Filtrage avancé",
    "Filter Messages": "Filtrer les messages",
    "use the fields below to filter messages than press filter button.": "utilisez les champs ci-dessous pour filtrer les messages puis appuyez sur le bouton de filtre.",
    "All": "Tous",
    "Operator": "Opérateur",
    "Status": "Statut",
    "Failed": "Échoué",
    "Sent": "Envoyé",
    "From": "De",
    "To": "À",
    "Filter scheduled messages": "Filtrer les messages programmés",
    "use the fields below to filter messages.": "utilisez les champs ci-dessous pour filtrer les messages.",
    "Send At": "Envoyer à",
    "year": "année",
    "month": "mois",
    "no repeat": "pas de répétition",
    "use the fields below to filter messages based on the date range you specify.": "utilisez les champs ci-dessous pour filtrer les messages en fonction de la plage de dates que vous spécifiez.",
    "Configurations": "Configurations",
    "SMS configuration": "Configuration SMS",
    "Source": "Source",
    "Username": "Nom d'utilisateur",
    "Password": "Mot de passe",
    "SMS senders": "Expéditeurs de SMS",
    "Confirm New Password": "Confirmer le nouveau mot de passe",
    "New Password": "Nouveau mot de passe",
    "Actuel Password": "Mot de passe actuel",
    "Security": "Sécurité",
    "Personal Information": "Informations personnelles",
    "Disconnect": "Déconnexion",
    "Mailing lists": "Listes de diffusion",
    "Mailing contacts": "Contacts de diffusion",
    "Apps": "Applications",
    "Profile": "Profil",
    "Mailing Lists": "Listes de diffusion",
    "Mailing Contacts": "Contacts de diffusion",
    "set my Account!": "configurer mon compte !",
    "unable to send message before setting your SMS sending account": "impossible d'envoyer un message avant de configurer votre compte d'envoi de SMS",
    "Create Mailing List": "Créer une liste de diffusion",
    "Reply to (option)": "Répondre à (option)",
    "Subject": "Sujet",
    "Editor type": "Type d'éditeur",
    "Attach files": "Joindre des fichiers",
    "Program": "Programmer",
    "Send": "Envoyer",
    "Code": "Code",
    "fill the field below then press programme, this action will send your message at the selected time.": "remplissez le champ ci-dessous puis appuyez sur programmer, cette action enverra votre message à l'heure sélectionnée.",
    "select date": "sélectionner une date",
    "Create New Mailing List": "Créer une nouvelle liste de diffusion",
    "This is a quick way to mailing contact and assemble it in a new mailing list.": "Ceci est un moyen rapide de créer un contact de diffusion et de l'assembler dans une nouvelle liste de diffusion.",
    "Mailing list": "Liste de diffusion",
    "Mailing contact": "Contact de diffusion",
    "New": "Nouveau",
    "fill the field below then press create, this action will create a new Mailing List.": "remplissez le champ ci-dessous puis appuyez sur créer, cette action créera une nouvelle liste de diffusion.",
    "title": "titre",
    "Edit list title": "Modifier le titre de la liste",
    "Delete list": "Supprimer la liste",
    "import new contacts": "importer de nouveaux contacts",
    "Delete contacts": "Supprimer les contacts",
    "Remove from list": "Retirer de la liste",
    "Export contacts": "Exporter les contacts",
    "Edit Title": "Modifier le titre",
    'Once you are satisfied with your new title, press the "update" button to update your list title.': "Une fois que vous êtes satisfait de votre nouveau titre, appuyez sur le bouton 'mettre à jour' pour mettre à jour le titre de votre liste.",
    "Update": "Mettre à jour",
    "Add your contacts.": "Ajoutez vos contacts.",
    "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files": "Support pour un téléchargement unique ou en masse. Strictement interdit de télécharger des données d'entreprise ou d'autres fichiers",
    "Download our": "Téléchargez notre",
    "Configrations of the imported file": "Configurations du fichier importé",
    "Phone (obligated)": "Téléphone (obligatoire)",
    "Delete confirmation": "Confirmation de suppression",
    "Are you sure to delete this": "Êtes-vous sûr de vouloir supprimer cela",
    "Delete": "Supprimer",
    "Remove Contacts": "Retirer les contacts",
    "Are you sure to remove the contacts from this list ?": "Êtes-vous sûr de vouloir retirer les contacts de cette liste ?",
    "Add to list": "Ajouter à la liste",
    "Mailing List": "Liste de diffusion",
    "Add your contacts to a mailing list.": "Ajoutez vos contacts à une liste de diffusion.",
    "Select a list": "Sélectionnez une liste",
    "Remove": "Retirer",
    "Are you sure to delete this ": "Êtes-vous sûr de vouloir supprimer cela ",
    "Add List": "Ajouter une liste",
    "You can easily add or remove this mailing contact from your mailing lists.": "Vous pouvez facilement ajouter ou retirer ce contact de diffusion de vos listes de diffusion.",
    "SMTP configuration": "Configuration SMTP",
    "Email Address": "Adresse e-mail",
    "SMTP server host": "Hôte du serveur SMTP",
    "Port": "Port",
    "SMTP server port": "Port du serveur SMTP",
    "Account username": "Nom d'utilisateur du compte",
    "Account password": "Mot de passe du compte",
    "SMTP Accounts": "Comptes SMTP",
    "messages": "messages",
    "hour": "heure",
    "SMTP Verification": "Vérification SMTP",
    "We have send a code to your email address, enter the code to validate your smtp account.": "Nous avons envoyé un code à votre adresse e-mail, saisissez le code pour valider votre compte SMTP.",
    "check": "vérifier",
    "SMTP Settings": "Paramètres SMTP",
    "Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host.": "Veuillez indiquer la fréquence à laquelle vous souhaitez que ce serveur envoie des e-mails par heure, cette action s'appliquera à tous les SMTP sur le même hôte.",
    "Limit per hour": "Limite par heure",
    "Close": "Fermer",
    "are you sure you want to delete this email?": "êtes-vous sûr de vouloir supprimer cet e-mail ?",
    "no item is selected, choose in minimum one item.": "aucun élément n'est sélectionné, choisissez au moins un élément.",
    "Delete Email": "Supprimer l'e-mail",
    "refresh": "rafraîchir",
    "The requested url not found, it seems like it changed or deleted.": "L'URL demandée n'a pas été trouvée, il semble qu'elle ait changé ou été supprimée.",
    "URL Not Found!": "URL non trouvée!",
    "Login to your account": "Connectez-vous à votre compte",
    "The faster you fill up, the faster your business grow.": "Plus vous remplissez rapidement, plus votre entreprise se développe rapidement.",
    "login": "connexion",
    "Reset password.": "Réinitialiser le mot de passe.",
    "Contact admin": "Contacter l'administrateur",
    "You don't have an account?": "Vous n'avez pas de compte ?",
    "Let us help you grow your business": "Laissez-nous vous aider à développer votre entreprise",
    "We offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.": "Nous offrons un outil marketing très utile pour vous aider à développer votre entreprise rapidement et professionnellement. Plus vous remplissez rapidement, plus votre entreprise se développe rapidement.",
    "Our Conditions & Terms.": "Nos conditions et termes.",
    "Reset your password": "Réinitialisez votre mot de passe",
    "You can reset your password using email to find your account.": "Vous pouvez réinitialiser votre mot de passe en utilisant votre e-mail pour trouver votre compte.",
    "Reset Password": "Réinitialiser le mot de passe",
    "We have already send you a password reset link to your email address, check your mail inbox to reset your password.": "Nous vous avons déjà envoyé un lien de réinitialisation de mot de passe à votre adresse e-mail, vérifiez votre boîte de réception pour réinitialiser votre mot de passe.",
    "We regret to inform you that your account has been suspended.": "Nous regrettons de vous informer que votre compte a été suspendu.",
    "Account Suspended": "Compte suspendu",
    "Admin panel": "Panneau d'administration",
    "Please enter your credentials...": "Veuillez entrer vos identifiants...",
    "Please enter your credentials..": "Veuillez entrer vos identifiants..",
    "signup": "s'inscrire",
    "First name": "Prénom",
    "Family name": "Nom de famille",
    "Confirm password": "Confirmer le mot de passe",
    "Clients": "Clients",
    "Home": "Accueil",
    "Settings": "Paramètres",
    "Net profit": "Bénéfice net",
    "Total Profit": "Bénéfice total",
    "Balance": "Solde",
    "History of SMS messages": "Historique des messages SMS",
    "History of account balance recharging": "Historique des recharges de solde de compte",
    "Sender ID": "ID de l'expéditeur",
    "This month": "Ce mois-ci",
    "Total Consumed": "Total consommé",
    "with profit": "avec bénéfice",
    "sent": "envoyé",
    "Operators": "Opérateurs",
    "We will be back soon!": "Nous serons de retour bientôt !",
    "This page is currently under development": "Cette page est actuellement en développement",
    "Create": "Créer",
    "Clients management": "Gestion des clients",
    "Delete client": "Supprimer le client",
    "Are you sure you want to delete this account? This action is irreversible, and all the client data will be permanently deleted.": "Êtes-vous sûr de vouloir supprimer ce compte ? Cette action est irréversible et toutes les données du client seront définitivement supprimées.",
    "Create a new client": "Créer un nouveau client",
    "Client details": "Détails du client",
    "Phone number": "Numéro de téléphone",
    "Login credentials": "Identifiants de connexion",
    "Generate": "Générer",
    "Send login credantials to this email.": "Envoyer les identifiants de connexion à cet e-mail.",
    "Add sold": "Ajouter du crédit",
    "You can add sold easily from here.": "Vous pouvez ajouter du crédit facilement d'ici.",
    "Sold": "Crédit",
    "Please indicate how frequently you would like this server to send emails per hour, this action will apply for all SMTPs in the same host..": "Veuillez indiquer la fréquence à laquelle vous souhaitez que ce serveur envoie des e-mails par heure, cette action s'appliquera à tous les SMTP sur le même hôte.",
    "Ban client": "Bannir le client",
    "Are you sure you want to ban this account? The client will lose access to their account.": "Êtes-vous sûr de vouloir bannir ce compte ? Le client perdra l'accès à son compte.",
    "Ban in only 3 days": "Bannir dans seulement 3 jours",
    "Ban": "Bannir",
    "Delete SMTP config": "Supprimer la configuration SMTP",
    "Are you sure you want to delete this SMTP config? This action is irreversible.": "Êtes-vous sûr de vouloir supprimer cette configuration SMTP ? Cette action est irréversible.",
    "Delete sender id": "Supprimer l'ID de l'expéditeur",
    "Are you sure you want to delete this sender id? This action is irreversible.": "Êtes-vous sûr de vouloir supprimer cet ID d'expéditeur ? Cette action est irréversible.",
    "loading": "chargement",
    "Client Subscription": "Abonnement client",
    "Client subscription and management": "Abonnement et gestion des clients",
    "Manage Subscription": "Gérer l'abonnement",
    "days left": "jours restants",
    "You can edit subscription days": "Vous pouvez modifier les jours d'abonnement",
    "Days": "Jours",
    "You can edit or delete accounts": "Vous pouvez modifier ou supprimer des comptes",
    "email per hour": "e-mails par heure",
    "Sold Left": "Crédit restant",
    "SMS Configuration": "Configuration SMS",
    "Client can use his own Sender id": "Le client peut utiliser son propre ID d'expéditeur",
    "Sender id": "ID d'expéditeur",
    "SMS Statistiques": "Statistiques SMS",
    "NIC": "NIC",
    "subscription": "abonnement",
    "Email settings": "Paramètres de l'e-mail",
    "SMS settings": "Paramètres SMS",
    "History": "Historique",
    "Additional informations": "Informations supplémentaires",
    "Name": "Nom",
    "Location": "Emplacement",
    "Website": "Site Web",
    "Mail": "E-mail",
    "Phone": "Téléphone",
    "Bank account": "Compte bancaire",
    "Postal account": "Compte postal",
    "Commercial Register No": "Numéro de registre commercial",
    "AI": "IA",
    "NIF": "NIF",
    "client name": "nom du client",
    "ban": "bannir",
    "unban": "débannir",
    "unbanning": "débanissement",
    "back": "retour",
    "Reset your password to be able to connect again to your account": "Réinitialisez votre mot de passe pour pouvoir vous reconnecter à votre compte",
    "we offre a very helpful marketing tool to help you grow your business fastly and professionaly. the fast you fill up, the fast your business grow.": "nous offrons un outil marketing très utile pour vous aider à développer votre entreprise rapidement et professionnellement. Plus vous remplissez rapidement, plus votre entreprise se développe rapidement.",
    "SignUp": "Inscription",
    "Email confirmation failed!": "La confirmation de l'e-mail a échoué !",
    "Reset": "Réinitialiser",
    "internal server error": "erreur interne du serveur",
    "Internal server error": "Erreur interne du serveur",
    "Session ended": "Session terminée",
    "session ended": "session terminée",
    "An unexpected error has occured": "Une erreur inattendue est survenue",
    "Item added successfuly": "Élément ajouté avec succès",
    "Sending now! For updates, visit": "Envoi en cours ! Pour les mises à jour, visitez",
    "reporting page": "page de rapport",
    "This message added successfuly to your Message scheduling list": "Ce message a été ajouté avec succès à votre liste de planification de messages",
    "For a better experience, keep the message under 320 characters.": "Pour une meilleure expérience, gardez le message en dessous de 320 caractères.",
    "Last name": "Nom de famille",
    "Full name": "Nom complet",
    "Groups": "Groupes",
    "Action": "Action",
    "Exported successfuly": "Exporté avec succès",
    "No item is checked": "Aucun élément n'est coché",
    "Saved successfully": "Enregistré avec succès",
    "No item is selected, choose in minimum one item": "Aucun élément n'est sélectionné, choisissez au moins un élément",
    "Ok": "D'accord",
    "Are you sure you want to delete this items": "Êtes-vous sûr de vouloir supprimer ces éléments",
    "Items deleted successfully": "Éléments supprimés avec succès",
    "of": "de",
    "items Added successfully": "éléments ajoutés avec succès",
    "Item updated successfuly": "Élément mis à jour avec succès",
    "Deleted successfuly": "Supprimé avec succès",
    "Are you sure you want to delete this item ?": "Êtes-vous sûr de vouloir supprimer cet élément ?",
    "Created in": "Créé en",
    "deleted_contact": "contact supprimé",
    "Date": "Date",
    "RESEND": "RÉENVOYER",
    "failed": "échec",
    "Description": "Description",
    "Item deleted successfully": "Élément supprimé avec succès",
    "Error: There was an error processing your request": "Erreur : Une erreur s'est produite lors du traitement de votre demande",
    "Service Unavailable: The server is currently unable to handle the request": "Service indisponible : Le serveur est actuellement incapable de traiter la demande",
    "Internal Server Error: The server has encountered a situation it does not know how to handle": "Erreur interne du serveur : Le serveur a rencontré une situation qu'il ne sait pas gérer",
    "Too Many Requests: The user has sent too many requests in a given amount of time": "Trop de demandes : L'utilisateur a envoyé trop de demandes dans un laps de temps donné",
    "Method Not Allowed: The request method is not supported for the requested resource": "Méthode non autorisée : La méthode de la requête n'est pas supportée pour la ressource demandée",
    "Not Found: The server cannot find the requested resource": "Non trouvé : Le serveur ne peut pas trouver la ressource demandée",
    "Forbidden: The server understood the request but refuses to authorize it": "Interdit : Le serveur a compris la demande mais refuse de l'autoriser",
    "Unauthorized: Authentication is required, and the user has not provided valid credentials": "Non autorisé : L'authentification est requise et l'utilisateur n'a pas fourni d'identifiants valides",
    "Bad Request: The server cannot process the request due to an invalid syntax": "Mauvaise demande : Le serveur ne peut pas traiter la demande en raison d'une syntaxe invalide",
    "Saved successfuly": "Enregistré avec succès",
    "Too many requests": "Trop de demandes",
    "There is no selected file": "Aucun fichier sélectionné",
    "Cannot uplaod more than 10 files": "Impossible de télécharger plus de 10 fichiers",
    "Are you sure you want to delete this item": "Êtes-vous sûr de vouloir supprimer cet élément",
    "List": "Liste",
    "Black List": "Liste noire",
    "Receiver": "Destinataire",
    "Created At": "Créé le",
    "Edit": "Modifier",
    "File uploaded successfully": "Fichier téléchargé avec succès",
    "You must upload a csv file only": "Vous devez télécharger uniquement un fichier CSV",
    "file upload failed": "échec du téléchargement du fichier",
    "already exist.": "existe déjà.",
    "Exported successfully": "Exporté avec succès",
    "No contact is selected": "Aucun contact n'est sélectionné",
    "Item added successfully": "Élément ajouté avec succès",
    "Item updated successfully": "Élément mis à jour avec succès",
    "Updated successfully": "Mis à jour avec succès",
    "found": "trouvé",
    "created": "créé",
    "Lists": "Listes",
    "Removed from list": "Supprimé de la liste",
    "Item listed successfuly": "Élément listé avec succès",
    "Added to list": "Ajouté à la liste",
    "Deleted successfully": "Supprimé avec succès",
    "New config added": "Nouvelle configuration ajoutée",
    "Applyed successfully": "Appliqué avec succès",
    "Receivers ( lists )": "Destinataires (listes)",
    "Send at": "Envoyer à",
    "Process": "Processus",
    "Emails": "E-mails",
    "Pending": "En attente",
    "Opened": "Ouvert",
    "Impression": "Impression",
    "Bounced": "Rebondi",
    "Deleting": "Suppression",
    "Count": "Nombre",
    "Email canceled successfully": "E-mail annulé avec succès",
    "Login out..": "Déconnexion...",
    "No item is selected": "Aucun élément n'est sélectionné",
    "Client": "Client",
    "Days left": "Jours restants",
    "Expiration date": "Date d'expiration",
    "Expired": "Expiré",
    "Active": "Actif",
    "Banned": "Banni",
    "Actions": "Actions",
    "Client deleted successfully": "Client supprimé avec succès",
    "Client banned successfully": "Client banni avec succès",
    "Client Unbanned successfully": "Client débanni avec succès",
    "Subscription days changed successfully": "Jours d'abonnement modifiés avec succès",
    "Sold added successfully": "Solde ajouté avec succès",
    "Limit changed successfully": "Limite modifiée avec succès",
    "Smtp account deleted successfully": "Compte SMTP supprimé avec succès",
    "SenderId created successfully": "Identifiant d'expéditeur créé avec succès",
    "Actor": "Acteur",
    "Consumed": "Consommé",
    "Price": "Prix",
    "Passwords are not the same.": "Les mots de passe ne sont pas identiques.",
    "email or password are wrong": "email ou mot de passe incorrect",
    "connected successfully": "connecté avec succès",
    "password field is empty": "le champ mot de passe est vide",
    "email is not valid": "l'email n'est pas valide",
    "email field is empty": "le champ email est vide",
    "Password should be at least 8 characters long": "Le mot de passe doit contenir au moins 8 caractères",
    "Email is required": "L'email est requis",
    "Email already exist": "L'email existe déjà",
    "Please enter a valid email": "Veuillez entrer un email valide",
    "Please enter a valid phone number": "Veuillez entrer un numéro de téléphone valide",
    "Please enter a DZ phone number": "Veuillez entrer un numéro de téléphone DZ",
    "Society name can't be more than 80 letters": "Le nom de la société ne peut pas dépasser 80 lettres",
    "Family name can't be more than 80 letters": "Le nom de famille ne peut pas dépasser 80 lettres",
    "First name can't be more than 80 letters": "Le prénom ne peut pas dépasser 80 lettres",
    "New user created successfully": "Nouvel utilisateur créé avec succès",
    "New user created successfully, but an unexpected error has occured while sending the email": "Nouvel utilisateur créé avec succès, mais une erreur inattendue s'est produite lors de l'envoi de l'email",
    "A new client account created": "Un nouveau compte client a été créé",
    "Password is required": "Le mot de passe est requis",
    "Password should contain at least one special character": "Le mot de passe doit contenir au moins un caractère spécial",
    "Password should contain at least one digit": "Le mot de passe doit contenir au moins un chiffre",
    "Password should contain at least one lowercase letter": "Le mot de passe doit contenir au moins une lettre minuscule",
    "Password should contain at least one uppercase letter": "Le mot de passe doit contenir au moins une lettre majuscule",
    "The changes have been saved successfully": "Les modifications ont été enregistrées avec succès",
    "Client information changed": "Informations client modifiées",
    "Client not found, refresh the page and try again.": "Client introuvable, actualisez la page et réessayez.",
    "Smtp account not found, refresh the page and try again.": "Compte SMTP introuvable, actualisez la page et réessayez.",
    "Sold value must be a number": "La valeur du solde doit être un nombre",
    "Client not found, refresh the page and try again": "Client introuvable, actualisez la page et réessayez",
    "Days value must be a number": "La valeur des jours doit être un nombre",
    "SenderId not found, refresh the page and try again": "Identifiant d'expéditeur introuvable, actualisez la page et réessayez",
    "Sender id account not found, refresh the page and try again.": "Compte d'identifiant d'expéditeur introuvable, actualisez la page et réessayez.",
    "Enter a valid amount": "Entrez un montant valide",
    "SenderId is required": "L'identifiant d'expéditeur est requis",
    "This senderId already exist for another client": "Cet identifiant d'expéditeur existe déjà pour un autre client",
    "SenderId already exist": "L'identifiant d'expéditeur existe déjà",
    "Phone number already exist": "Le numéro de téléphone existe déjà",
    "Enter a valid phone number": "Entrez un numéro de téléphone valide",
    "Group name already exist": "Le nom du groupe existe déjà",
    "Number only": "Nombre seulement",
    "String only": "Chaîne seulement",
    "Phone number is required": "Le numéro de téléphone est requis",
    "Enter a valid society name": "Entrez un nom de société valide",
    "Society is required": "La société est requise",
    "Enter a valid name": "Entrez un nom valide",
    "Group name is required": "Le nom du groupe est requis",
    "An error has occured, refresh your page and try again.": "Une erreur s'est produite, actualisez votre page et réessayez.",
    "Sending messages between 10pm and 7am is not allowed. Please try again later.": "L'envoi de messages entre 22h00 et 7h00 n'est pas autorisé. Veuillez réessayer plus tard.",
    "Message cannot exceed 1600 characters": "Le message ne peut pas dépasser 1600 caractères",
    "Message is required": "Le message est requis",
    "Contacts are required": "Les contacts sont requis",
    "Subject is required": "L'objet est requis",
    "Max lines reached (1000 per time)": "Nombre maximum de lignes atteint (1000 par fois)",
    "Phone number is obligated": "Le numéro de téléphone est obligatoire",
    "Date is not valid": "La date n'est pas valide",
    "Select a valid hour": "Sélectionnez une heure valide",
    "Hour is required": "L'heure est requise",
    "Repeted is required": "La répétition est requise",
    "Date is required": "La date est requise",
    "Message not found": "Message non trouvé",
    "Username is required": "Le nom d'utilisateur est requis",
    "Source Already exist": "La source existe déjà",
    "Valid source only": "Source valide seulement",
    "Source is required": "La source est requise",
    "Valid password only": "Mot de passe valide seulement",
    "Valid username only": "Nom d'utilisateur valide seulement",
    "Group not found": "Groupe non trouvé",
    "Name field is required": "Le champ nom est requis",
    "Group is already created": "Le groupe est déjà créé",
    "An error has occured while uploading your image": "Une erreur s'est produite lors du téléchargement de votre image",
    "Image only": "Image seulement",
    "Large image size": "Taille de l'image trop grande",
    "Max upload files reached": "Nombre maximum de fichiers téléchargés atteint",
    "Email or password are wrong": "Email ou mot de passe incorrect",
    "Connected successfully": "Connecté avec succès",
    "Email already taken": "Email déjà pris",
    "Email is not valid": "L'email n'est pas valide",
    "The requested item was not found. We apologize for the inconvenience.": "L'élément demandé n'a pas été trouvé. Nous nous excusons pour le désagrément.",
    "This link is not valid anymore, try to signup again.": "Ce lien n'est plus valide, essayez de vous inscrire à nouveau.",
    "The requested item is not valid anymore, try to signup again.": "L'élément demandé n'est plus valide, essayez de vous inscrire à nouveau.",
    "reloaded.": "rechargé.",
    "Your email is verified": "Votre email est vérifié",
    "The requested item is not valid anymore, try to again later.": "L'élément demandé n'est plus valide, essayez à nouveau plus tard.",
    "reloaded": "rechargé",
    "The link is verified": "Le lien est vérifié",
    "The requested item is not valid anymore, try again later.": "L'élément demandé n'est plus valide, essayez à nouveau plus tard.",
    "This link is not valid anymore, try again later.": "Ce lien n'est plus valide, essayez à nouveau plus tard.",
    "This link is not valid anymore, try again later": "Ce lien n'est plus valide, essayez à nouveau plus tard",
    "Check your inbox": "Vérifiez votre boîte de réception",
    "This session is not valid anymore, try signup again later.": "Cette session n'est plus valide, essayez de vous inscrire à nouveau plus tard.",
    "Password saved successfully": "Mot de passe enregistré avec succès",
    "Passwords don't match": "Les mots de passe ne correspondent pas",
    "Password confirmation is required": "La confirmation du mot de passe est requise",
    "Enter a valid password": "Entrez un mot de passe valide",
    "Your information saved": "Vos informations sont enregistrées",
    "Enter a valid family name": "Entrez un nom de famille valide",
    "Family name is required": "Le nom de famille est requis",
    "Name is required": "Le nom est requis",
    "Logged out successfully": "Déconnecté avec succès",
    "Incorrect password": "Mot de passe incorrect",
    "Password changed successfully": "Mot de passe changé avec succès",
    "New password is required": "Le nouveau mot de passe est requis",
    "Email Already exist": "L'email existe déjà",
    "Enter a valid email": "Entrez un email valide",
    "This title is already used": "Ce titre est déjà utilisé",
    "Title is required": "Le titre est requis",
    "Valid title only": "Titre valide seulement",
    "Group does not exist": "Le groupe n'existe pas",
    "Name of group is required": "Le nom du groupe est requis",
    "Group already exists": "Le groupe existe déjà",
    "Not Found": "Non trouvé",
    "Contact is in the list": "Le contact est dans la liste",
    "List not found": "Liste non trouvée",
    "Already exists, try make some changes.": "Existe déjà, essayez de faire des modifications.",
    "Server port is required": "Le port du serveur est requis",
    "Please enter a valid server port": "Veuillez entrer un port de serveur valide",
    "Server host is required": "L'hôte du serveur est requis",
    "Please enter a valid server host": "Veuillez entrer un hôte de serveur valide",
    "Please enter a valid SMTP password": "Veuillez entrer un mot de passe SMTP valide",
    "Smtp password is required": "Le mot de passe SMTP est requis",
    "Please enter a valid SMTP username": "Veuillez entrer un nom d'utilisateur SMTP valide",
    "Smtp username is required": "Le nom d'utilisateur SMTP est requis",
    "SMTP not found": "SMTP non trouvé",
    "Can not be more than 500 per hour": "Ne peut pas être plus de 500 par heure",
    "Limit is required": "La limite est requise",
    "There is no SMTP mentioned": "Aucun SMTP mentionné",
    "The code is not valid anymore, refresh and try again.": "Le code n'est plus valide, actualisez et réessayez.",
    "Invalid Code. Please check your code and try again.": "Code invalide. Veuillez vérifier votre code et réessayez.",
    "This Smtp Already exists, try make some changes.": "Ce SMTP existe déjà, essayez de faire des modifications.",
    "The code is not valid anymore, Max attempt reached.": "Le code n'est plus valide, tentative maximale atteinte.",
    "The code is not valid anymore, try again later.": "Le code n'est plus valide, réessayez plus tard.",
    "Enter a valid code": "Entrez un code valide",
    "Valid host only": "Hôte valide seulement",
    "Check your configs and try again.": "Vérifiez vos configurations et réessayez.",
    "Maximum emails reached. Your emails will be queued and sent as soon as possible.": "Nombre maximum d'emails atteint. Vos emails seront mis en file d'attente et envoyés dès que possible.",
    "You cannot send more than 10 files": "Vous ne pouvez pas envoyer plus de 10 fichiers",
    "Name is too large": "Le nom est trop grand",
    "Enter valid email": "Entrez un email valide",
    "Your page is empty, please add something.": "Votre page est vide, veuillez ajouter quelque chose.",
    "Please select a mailing list": "Veuillez sélectionner une liste de diffusion",
    "Please select an email": "Veuillez sélectionner un email",
    "Config does not exist, try refresh your page.": "La configuration n'existe pas, essayez d'actualiser votre page.",
    "Please select a value": "Veuillez sélectionner une valeur",
    "Please select the time": "Veuillez sélectionner l'heure",
    "Please select a date": "Veuillez sélectionner une date",
    "your page is empty, please add something.": "votre page est vide, veuillez ajouter quelque chose.",
    "Valid email only": "Email valide seulement",
    "Email not found, refresh your page and try again.": "Email non trouvé, actualisez votre page et réessayez.",
    "Maximum emails reached. Some emails will be queued and sent as soon as possible.": "Nombre maximum d'emails atteint. Certains emails seront mis en file d'attente et envoyés dès que possible.",
    "Emails are currently being sent, This may take some time.": "Les emails sont actuellement en cours d'envoi, cela peut prendre un certain temps.",
    "Invalid base64 string format": "Format de chaîne base64 invalide",
    "Email not found, refresh your page.": "Email non trouvé, actualisez votre page.",
    "Email scheduled successfully": "Email programmé avec succès",
    "Mailing list is required": "La liste de diffusion est requise",
    "Email already exists": "L'email existe déjà",
    "Invalid email": "Email invalide",
    "Connection timed out": "Délai de connexion dépassé",
    "Not connected to mail server": "Non connecté au serveur de messagerie",
    "Not connected to mail server (error)": "Non connecté au serveur de messagerie (erreur)",
    "Item not found": "Élément non trouvé",
    "File not found": "Fichier non trouvé",
    "Only PDF, PNG, JPEG, JPG files are allowed": "Seuls les fichiers PDF, PNG, JPEG, JPG sont autorisés",
    "The maximum allowed file size is 10 MB": "La taille de fichier maximale autorisée est de 10 Mo",
    "Client not found": "Client non trouvé",
    "Unable to send, set your SMS account config first": "Impossible d'envoyer, configurez d'abord votre compte SMS",
    "Insufficient credit, Recharge it and try again later.": "Crédit insuffisant, rechargez-le et réessayez plus tard.",
    "Insufficient credit, Recharge it and try again later": "Crédit insuffisant, rechargez-le et réessayez plus tard",
    "Account subscription has expired": "L'abonnement du compte a expiré",
    "email-field-is-empty": "L'email est requis",
    "password-field-is-empty": "Le mot de passe est requis",
    "email-is-not-valid": "Veuillez saisir une adresse e-mail valide",
    "Resend Message": "Renvoyer le message",
    '':'',
    "Save your information": "Enregistrer vos informations",
    "Save password": "Enregistrer le mot de passe",
    "Amount (DZD)": "Montant (DZD)",
    "Last balance (DZD)": "Solde précédent (DZD)",
    "New balance (DZD)": "Nouveau solde (DZD)",
    "Balance added can't be edited or deleted, click add button to add balance": "Le solde ajouté ne peut pas être modifié ou supprimé, cliquez sur le bouton d'ajout pour ajouter un solde",
    "Add balance": "Ajouter un solde",
    "Amount": "Montant",
    'insufficient credit, Recharge it and try again.':'crédit insuffisant, rechargez-le et réessayez.',
    'An error occurred by the service provider':'Une erreur est survenue chez le prestataire de services',
    'Message not sent because the user is banned.':"Message non envoyé car l'utilisateur est banni.",
    'Message does not exist.':"Le message n'existe pas.",
    'Contact does not exist.':"Le contact n'existe pas.",
    'create account':'créer un compte',
    '':'',
    '':'',
}

export default french;
